<template>
  <wk-header :currentIndex="4"></wk-header>
  <el-main style="padding-bottom: 80px">
    <el-row >
      <img src="./../assets/img/banner.webp" class="wk-banner">
      <div class="wk-banner-title">
        <div>
          <h2 style="font-size: 4.5em">嗨微客</h2>
          <h2 class="title_desc">
            <span>行业工具一网扫尽</span>
            <span>行业资讯一览无遗</span>
          </h2>
          <!-- <h3>嘟聪嗨微客您创业的好助手</h3> -->
        </div>
      </div>
    </el-row>
    <el-row>
      <el-col :span="18" :offset="3">
        <h1 class="center">关于我们</h1>
        <el-row  class="flex-center mt50">
          <el-col :span="10">
            <h2 class="root-acti">公司简介</h2>
            <p class="gray l5">北京嘟聪文化传媒有限公司（以下简称“嘟聪”），是一家专注于创新创业领域的公司，始终以帮扶中小企业实体经济为主要链路，秉持着为数以百万计的微客创业者服务的初心，旗下运营的嗨微客平台结合物联网系统锻造方案，将平台资源分析整合，帮助微客创业者在市场繁杂的工具中准确定位，找到契合自身需求的软件工具。未来，嘟聪还将通过对嗨微客平台的完善，继续推动行业赋能新升级！</p>
          </el-col>
          <el-col :span="14">
            <img src="./../assets/img/about-1.png" class="w100">
          </el-col>
        </el-row>

        <el-row  class="flex-center mt50">
          <el-col :span="14">
            <img src="./../assets/img/about-2.png" class="w100">
          </el-col>
          <el-col :span="10" class="about-second">
            <h2 class="root-acti">公司理念</h2>
            <h3>愿景</h3>
            <p class="mt10">让每一位创业者不再孤单</p>
            <h3>使命</h3>
            <p class="mt10">让每一位创业者所求皆如愿，所行化坦途</p>
            <h3>定位</h3>
            <p class="mt10">一个有内容有温度的创业者家园</p>
          </el-col>
        </el-row>
        <h1 class="center">联系我们</h1>
        <el-row  class="flex-center mt50">

          <el-col :span="14">
            <img src="./../assets/img/about-3.png" class="w100">
          </el-col>
          <el-col :span="10" class="about-third">
            <div>
              <h3>邮箱</h3>
              <p class="gray">hiweke@hiweke.com</p>
            </div>
            <div class="center">
              <img src="./../assets/img/ewm.png">
              <p class="gray">联系嗨微客</p>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-main>
  <wk-footer></wk-footer>
</template>
<script>
import WkHeader  from '@/components/WKHeader'
import WkFooter from '@/components/WKFooter'
export default {
  name: 'About',
  components: {
    WkHeader,
    WkFooter
  }
}
</script>

<style scoped>
.el-main {
  padding-left: 0;
  padding-right: 0;
}
.wk-banner {
  width: 100%;
  position: relative;
}
.wk-banner-title {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}
.wk-banner-title h1 {
  font-size: 5em;
}
.wk-banner-title h3 {
  font-size: 2.5em;
  margin-top: 0.5em;
  font-weight: normal;
}
.gray.l5 {
  line-height: 1.8em;
  padding-right: 12%;
}
.about-second {
  position: relative;
  background-color: #DCE2EA;
  padding: 40px 5%;

}
.about-second::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 30%;
  background-color: #DCE2EA;
  opacity: 0.5;
  left: -30%;
  top: 0;

}
.about-second h3 {
  margin-top: 20px;
}

.about-second > h3::before {
  content: '';
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  background-color: #1E87FF;
  margin-right: 10px;
  vertical-align: middle;
  transform: rotate(45deg) translateY(-50%);
}
.about-third {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5%;
  background-color: #F5F5F5;
}
.about-third .center> img {
  width: 100px;
}
.el-col h1 {
  margin-top: 80px;
}
.title_desc {
  font-size: 3.5em;
}
.title_desc span {
  display: inline-block;
}
.title_desc span:last-child {
  margin-left: 1em;
}
</style>
